'use client';

import React, { useState, useEffect } from 'react';
import { Input } from './Input';
import { TextArea } from './TextArea';
import { Button } from '@/components/Button';
import { City, SecondaryServices } from '@/types';
import ServiceSelect from './ServiceSelect';
import CitySelect from './CitySelect';
import { Modal } from '@/components/Modal';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { verifyCaptcha } from '@/lib/captcha';
import { Location } from '@/context/location';
import { removeAccents } from '@/utils/removeAccents';
import ReactFlagsSelect from 'react-flags-select';

interface Props {
  citiesProp: Array<City>;
  primaryServiceProp: string;
  secondaryServiceProp: Array<SecondaryServices>;
  cidadeProp?: string;
}

interface SelectedOption {
  value: number;
  label: string;
  labelSlug: string;
}

export function Orcamento({
  primaryServiceProp,
  secondaryServiceProp,
  citiesProp,
  cidadeProp,
}: Props) {
  const { cidadeLocation } = Location();

  const pathname = usePathname();

  // @ts-ignore
  const secondaryService = pathname.split('/')[2];

  const { executeRecaptcha } = useGoogleReCaptcha();
  // @ts-ignore
  const term: string = secondaryService || '';

  const [selectedService, setSelectedService] = useState<SelectedOption[]>([]);
  const [serviceSimpleArray, setserviceSimpleArray] = useState<number[]>([]);
  const [serviceNameSimpleArray, setserviceNameSimpleArray] = useState<
    string[]
  >([]);

  const [selectedCountry, setSelectedCountry] = useState('');

  const [cityInputValue, setCityInputValue] = useState('');
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [filteredCities, setFilteredCities] = useState<
    { value: string; label: string }[]
  >([]);

  const [detalhes, setDetalhes] = useState('');
  const [nome, setNome] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [cidade, setCidade] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hasSent, setHasSent] = useState(false);

  const [servicesSelect, setServicesSelect] = useState<
    { value: number; label: string; labelSlug: string }[]
  >([]);
  const [cityOptions, setCityOptions] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    const services = secondaryServiceProp.map((service) => ({
      value: service.Id,
      label: service.Name,
      labelSlug: service.SlugName,
    }));
    setServicesSelect(services);

    const cities = citiesProp.map((city) => ({
      value: city.Name,
      label: city.UFName,
    }));
    setCityOptions(cities);

    setSelectedCountry('BR');
  }, [secondaryServiceProp, citiesProp]);

  useEffect(() => {
    if (term) {
      const filteredItems = servicesSelect.filter(
        (item) => item.labelSlug === term
      );
      handleSelectChange(filteredItems);
    }
  }, [servicesSelect]);

  const handleSelectChange = (selectedOptions: SelectedOption[]) => {
    const selectedValuesSimpleArray: number[] = selectedOptions.map(
      (option) => option.value
    );
    const selectedNameValuesSimpleArray: string[] = selectedOptions.map(
      (option) => option.label
    );
    setserviceSimpleArray(selectedValuesSimpleArray);
    setserviceNameSimpleArray(selectedNameValuesSimpleArray);
    setSelectedService(selectedOptions);
  };

  const handleCityChange = (selectedCity: { label: string }) => {
    setCidade(selectedCity.label);
  };

  const handleCityInputChange = (inputValue: string) => {
    setCityInputValue(inputValue);
  };

  const handleNomeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNome(e.target.value);
  };

  const handleWhatsappChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, '');

    let maskedValue = '';
    if (numericValue.length <= 2) {
      maskedValue = numericValue;
    } else if (numericValue.length <= 7) {
      maskedValue = `(${numericValue.slice(0, 2)}) ${numericValue.slice(2)}`;
    } else if (numericValue.length <= 11) {
      maskedValue = `(${numericValue.slice(0, 2)}) ${numericValue.slice(
        2,
        7
      )}-${numericValue.slice(7)}`;
    } else {
      maskedValue = `(${numericValue.slice(0, 2)}) ${numericValue.slice(
        2,
        7
      )}-${numericValue.slice(7, 11)}`;
    }

    setWhatsapp(maskedValue);
  };

  useEffect(() => {
    const fetchCities = async () => {
      try {
        setIsLoadingCities(true);
        await new Promise((resolve) => setTimeout(resolve, 500));
        const filteredCities = cityOptions.filter((city) =>
          removeAccents(city.label.toLowerCase()).includes(
            removeAccents(cityInputValue.toLowerCase())
          )
        );
        setFilteredCities(filteredCities);
        setIsLoadingCities(false);
      } catch (error) {
        console.error('Erro ao buscar cidades:', error);
        setIsLoadingCities(false);
      }
    };

    if (cityInputValue && cityInputValue.trim().length > 0) {
      fetchCities();
    } else {
      setFilteredCities([]);
    }
  }, [cityInputValue]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('onSubmit');

    const verified = await verifyCaptcha(token);

    if (verified) {
      try {
        const numericWhatsapp = whatsapp.replace(/\D/g, '');

        const data = {
          PrimaryServiceName: primaryServiceProp,
          ServicesNames: serviceNameSimpleArray,
          ServicesIds: serviceSimpleArray,
          Detalhes: detalhes,
          Nome: nome,
          WhatsappUser: 55 + numericWhatsapp,
          Cidade: cidade,
          Limit: 999,
        };

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ data }),
        };

        if (await fetch('/api/sendOrcamentoApi', requestOptions)) {
          setShowModal(true);
          setHasSent(true);
        }
      } catch (error) {
        alert('Erro ao enviar o orçamento');
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div className='bg-frame p-5 md:p-10'>
        <form className='w-full' onSubmit={handleSubmit}>
          {servicesSelect && (
            <ServiceSelect
              selectedService={selectedService}
              handleSelectChange={handleSelectChange}
              servicesSelect={servicesSelect}
            />
          )}

          <TextArea
            className='input-pattern my-2 w-full placeholder:text-[#565656]'
            required
            placeholder='Detalhes adicionais sobre o serviço'
            name='detalhes'
            icon='mdi-receipt-text-edit-outline'
            value={detalhes}
            onChange={(e) => setDetalhes(e.target.value)}
          />

          <div className='md:flex  md:justify-between'>
            <Input
              className='input-pattern my-2 w-full'
              id='nome'
              type='text'
              required
              placeholder='Seu nome'
              name='nome'
              icon='mdi-account-outline'
              value={nome}
              onChange={handleNomeChange}
            />
            <div className='my-2 flex h-[48px] place-items-center rounded bg-[white] text-center lg:ml-3'>
              <ReactFlagsSelect
                selected={selectedCountry}
                onSelect={(code) => setSelectedCountry(code)}
                searchable
                searchPlaceholder='Pesquisar...'
                className='flag-button w-1/5'
                customLabels={{
                  BR: { primary: '+55' },
                }}
              />
              <Input
                id='whatsapp'
                type='tel'
                required
                placeholder='Seu WhatsApp'
                name='whatsapp'
                value={whatsapp}
                onChange={handleWhatsappChange} // Fix: Pass the correct handler
                className='w-4/5'
              />
            </div>
          </div>

          {cityOptions && (
            <CitySelect
              filteredCities={filteredCities}
              cityOptions={cityOptions}
              cidade={cidade}
              isLoadingCities={isLoadingCities}
              handleCityInputChange={handleCityInputChange}
              handleCityChange={handleCityChange}
              defaultValue={cidadeProp ?? cidadeLocation}
            />
          )}

          <div className='my-2'>
            <input
              type='checkbox'
              id='concordo-termos-e-politicas'
              required
              className='mr-2 h-4 w-4'
            />
            <label
              htmlFor='concordo-termos-e-politicas'
              className='text-gray-light'
            >
              Estou ciente dos{' '}
              <Link
                href='/termos-e-politicas'
                target='_blank'
                className='underline'
              >
                termos e políticas
              </Link>
            </label>
          </div>

          <Button
            type='submit'
            disabled={loading || hasSent}
            className='input-pattern text-bg-secondary border-blue-700 my-2 flex w-full items-center justify-center rounded border px-4 py-2 text-center font-bold'
          >
            {loading ? 'Enviando...' : 'Enviar'}
          </Button>
        </form>
      </div>

      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        haveSvg={true}
        className='modal-orcamento'
      >
        <p className='mb-4 text-2xl font-bold'>Prontinho!</p>
        <p>
          Fica ligado no seu <b>WhatsApp</b>, que em breve você começará a
          receber orçamentos!
        </p>
      </Modal>
    </div>
  );
}
