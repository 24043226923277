import React from 'react';
import { City, PrimaryServices, Rating } from '@/types';
import { Rating as RatingComponent } from '@/components/Rating';

type Props = {
  DataSiteServer: {
    PrimaryServices: PrimaryServices
    City: City,
    Rating: Rating
  }
}

export function Content({ ...props }: Props) {
  const { DataSiteServer } = props;
  const { Rating, City } = DataSiteServer;
  const Name = DataSiteServer.PrimaryServices.SecondaryServices[0].Name ?? DataSiteServer.PrimaryServices.Name;

  return (
    <div className={'p-7 text-center'}>
      <div className='flex justify-center'>
        <RatingComponent rating={Rating.RatingSum} />
      </div>
      {Rating.TotalVotes} usuários
      avaliaram
      nossos prestadores de serviços
      de <b>{Name} </b>
      {
        City.Name &&
        <span>em <b>{City.Name}, {City.State}</b></span>
      }
    </div>
  );
}
