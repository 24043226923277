'use client';

import React from 'react';
import Section from '@/components/Layout/Section';
import Image from 'next/image';
import { Orcamento } from '@/components/Forms/Orcamento';
import { Content } from '@/components/Blocks/FirstBlockSegmentos/Content';
import { Card } from '@/components/Card';
import { motion } from 'framer-motion';
import { DataSiteServer, City } from '@/types';

interface Props {
  dataSiteServer: DataSiteServer;
  cities: Array<City>;
}

export function Mobile({ dataSiteServer, cities }: Props) {
  const Name =
    dataSiteServer.PrimaryServices.SecondaryServices[0].Name ??
    dataSiteServer.PrimaryServices.Name;

  return (
    <>
      <div>
        <Section>
          {dataSiteServer && (
            <>
              {dataSiteServer.City.Name ? (
                <h1 className='text-2xl'>
                  de <b>{Name}</b> em {dataSiteServer.City.Name},{' '}
                  {dataSiteServer.City.State}
                  <b>
                    {dataSiteServer.City.Name}, {dataSiteServer.City.State}
                  </b>
                  ?
                </h1>
              ) : (
                <h1 style={{ fontSize: '19px' }}>
                  Precisa de <b>{Name}</b>?
                </h1>
              )}
            </>
          )}

          <div className='grid grid-cols-2'>
            <div>
              <p className='fs-12 mt-2 w-64'>
                Faça orçamento com vários negócios ao mesmo tempo de uma única
                vez!
              </p>
              <div className='mt-5 w-44'>
                <p className={'flex items-center leading-loose'}>
                  <Image
                    width={20}
                    height={20}
                    src={'/segmentos/Vector.svg'}
                    alt={'vector'}
                  />
                  <b className={'fs-12 line-height-sm pl-2'}>
                    Receba orçamentos em até 60 minutos
                  </b>
                </p>
                <p className={'mt-2 flex items-center leading-loose'}>
                  <Image
                    width={20}
                    height={20}
                    src={'/segmentos/whatsapp.svg'}
                    alt={'whatsapp'}
                  />
                  <b className={'fs-12 line-height-sm pl-2'}>
                    Receba orçamentos direto no WhatsApp
                  </b>
                </p>
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
            >
              <Image
                style={{ marginLeft: '20px' }}
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = '/home/foto-home.webp';
                }}
                width={120}
                height={400}
                src={dataSiteServer.Img}
                alt={''}
              />
            </motion.div>
          </div>

          <Orcamento
            primaryServiceProp={dataSiteServer.PrimaryServices.Name}
            secondaryServiceProp={dataSiteServer.SecondaryServices}
            cidadeProp={dataSiteServer.City.Name}
            citiesProp={cities}
          />

          <Card className={'mt-10 max-w-sm'}>
            <Content DataSiteServer={dataSiteServer} />
          </Card>
        </Section>
      </div>
    </>
  );
}
