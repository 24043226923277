'use client';

import React from 'react';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';
import { DataSiteServer, City } from '@/types';
import Media from 'react-responsive';

interface Props {
  dataSiteServer: DataSiteServer,
  cities: Array<City>
}

export function ChoseScreen({ dataSiteServer, cities }: Props) {
  return (
    <>
      <Media query='(max-width: 767px)'>
        {(matches) =>
          matches ? (
            <Mobile dataSiteServer={dataSiteServer} cities={cities} />
          ) : (
            <Desktop dataSiteServer={dataSiteServer} cities={cities} />
          )
        }
      </Media>
    </>
  );
}
