'use client';

import React from 'react';
import Section from '@/components/Layout/Section';
import { LRContent } from '@/components/Layout/Style';
import Image from 'next/image';
import { Orcamento } from '@/components/Forms/Orcamento';
import { Content } from '@/components/Blocks/FirstBlockSegmentos/Content';
import { RightContentAnimate } from '@/components/Layout/RightContentAnimate';
import { LeftContentAnimate } from '@/components/Layout/LeftContentAnimate';
import { Card } from '@/components/Card';
import { DataSiteServer, City } from '@/types';

interface Props {
  dataSiteServer: DataSiteServer;
  cities: Array<City>;
}

export function Desktop({ dataSiteServer, cities }: Props) {
  const Name =
    dataSiteServer.PrimaryServices.SecondaryServices[0].Name ??
    dataSiteServer.PrimaryServices.Name;

  return (
    <>
      <div className={'extended-bg'}>
        <Section>
          {dataSiteServer && (
            <>
              {dataSiteServer.City.Name ? (
                <h1 className='text-2xl'>
                  Precisa de <b>{Name}</b> em{' '}
                  <b>
                    {dataSiteServer.City.Name}, {dataSiteServer.City.State}
                  </b>
                  ?
                </h1>
              ) : (
                <h1 className='primary-service'>
                  Precisa de <b>{Name}</b>?
                </h1>
              )}
            </>
          )}

          <LRContent>
            <LeftContentAnimate>
              <p className={'mt-8 text-lg'}>
                Faça orçamento com vários negócios ao mesmo tempo
                <br /> de uma única vez!
              </p>
              <div className={'mt-6'}>
                <p className={'flex  items-center leading-loose'}>
                  <Image
                    width={18}
                    height={18}
                    src={'/segmentos/Vector.svg'}
                    alt={'vector'}
                  />
                  <b className={'pl-2'}>Receba orçamentos em até 60 minutos</b>
                </p>
                <p className={'flex items-center leading-loose'}>
                  <Image
                    width={18}
                    height={18}
                    src={'/segmentos/whatsapp.svg'}
                    alt={'whatsapp'}
                  />
                  <b className={'pl-2'}>
                    Receba orçamentos direto no WhatsApp{' '}
                  </b>
                </p>
              </div>
              <div className={'mt-8'}>
                <Orcamento
                  primaryServiceProp={dataSiteServer.PrimaryServices.Name}
                  secondaryServiceProp={dataSiteServer.SecondaryServices}
                  cidadeProp={dataSiteServer.City.Name}
                  citiesProp={cities}
                />
              </div>
            </LeftContentAnimate>

            <RightContentAnimate>
              <div>
                <Image
                  onError={(e) => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = '/home/foto-home.webp';
                  }}
                  width={322}
                  height={415}
                  style={{ width: 322, height: 415 }}
                  src={dataSiteServer.Img}
                  alt={`Segmento: ${dataSiteServer.PrimaryServices.Name}`}
                  priority
                  placeholder='empty'
                />
                <Card className={'max-w-sm'}>
                  <Content DataSiteServer={dataSiteServer} />
                </Card>
              </div>
            </RightContentAnimate>
          </LRContent>
        </Section>
      </div>
    </>
  );
}
