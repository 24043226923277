'use client';

import React, { useEffect, useState, CSSProperties } from 'react';
import Select, { StylesConfig } from 'react-select';

const customSelectStylesCidade: StylesConfig = {
  control: (base: any) => ({
    ...base,
    minHeight: 48,
    height: 56.1,
    paddingLeft: 5,
    fontSize: 16,
    color: '#BFBFBF',
    ':before': {
      content: `"\\F034E"`,
      fontFamily: 'Material Design Icons',
      fontSize: '19px',
      color: '#BFBFBF',
      marginLeft: 5,
    },
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#565656',
  }),
  menuList: (base: any) => ({
    ...base,
    height: 140,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};

interface Option {
  label: string;
  value: string;
}

interface CitySelectProps {
  filteredCities: Option[];
  cityOptions: Option[];
  cidade: string;

  isLoadingCities: boolean;
  handleCityInputChange: (inputValue: string) => void;
  handleCityChange: (selectedOption: Option) => void;
  defaultValue?: string;
}

const CitySelect = ({
  filteredCities,
  cityOptions,
  cidade,
  isLoadingCities,
  handleCityInputChange,
  handleCityChange,
  defaultValue,
}: CitySelectProps) => {
  const [selectedCity, setSelectedCity] = useState<string>(
    defaultValue || cidade
  ); // Definir como string

  const handleSelectChange = (selectedOption: any) => {
    if (selectedOption) {
      setSelectedCity(selectedOption.label);
      handleCityChange(selectedOption);
    }
  };

  const defaultCityOption = cityOptions.find(
    (option) => option.value === selectedCity
  );

  useEffect(() => {
    if (defaultCityOption) {
      handleCityChange(defaultCityOption);
    }
  }, [defaultCityOption]);
  return (
    <Select
      required
      styles={customSelectStylesCidade}
      className='input-pattern my-1 w-full'
      options={filteredCities}
      value={
        defaultCityOption ||
        cityOptions.find((option) => option.value === selectedCity)
      }
      onInputChange={handleCityInputChange}
      onChange={handleSelectChange}
      placeholder='Sua Cidade'
      noOptionsMessage={({ inputValue }) =>
        isLoadingCities ? 'Carregando...' : 'Digite o nome da sua cidade'
      }
    />
  );
};

export default CitySelect;
