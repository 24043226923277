import tw from 'tailwind-styled-components';

export const Heading = tw.span`
    block-title
`

export const LRContent = tw.div`
    flex 
    justify-between 
    md:flex-row 
    flex-col 
    items-center 
    text-center 
    md:text-start
    relative
`
export const LeftContent = tw.div`
    w-100 
    order-2 
    flex 
    flex-col 
    items-center 
    md:items-start
`

export const RightContent = tw.div`
     order-1 
     md:order-3
`