'use client';

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Section from '@/components/Layout/Section';
import { Card } from '@/components/Card';
import { Heading } from '@/components/Layout/Style';

interface FaqItem {
  Answers: string;
  Questions: string;
}

interface Props {
  title: string;
  faq: FaqItem[];
}

export function Faq({ faq, title }: Props) {
  const [openQuestionIndex, setOpenQuestionIndex] = useState<number | null>(
    null
  );
  const [isQuestionOpen, setIsQuestionOpen] = useState<boolean>(false);

  const handleQuestionClick = (index: number) => {
    if (openQuestionIndex === index) {
      setIsQuestionOpen(false);
      setOpenQuestionIndex(null);
    } else {
      setIsQuestionOpen(true);
      setOpenQuestionIndex(index);
    }
  };

  return (
    <>
      <Section>
        <Heading className='block-title'>{title}</Heading>
        {faq.map((item, index) => (
          <Card
            key={index}
            className='mt-3 md:cursor-pointer'
            onClick={() => handleQuestionClick(index)}
          >
            <div className='flex items-center'>
              <div>
                <motion.svg
                  width='15'
                  height='15'
                  viewBox='0 0 15 15'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  animate={{
                    rotate:
                      isQuestionOpen && openQuestionIndex === index ? 135 : 0,
                  }}
                  transition={{ duration: 0.3 }}
                >
                  <path
                    d='M2 7.8879H7.5M13 7.8879H7.5M7.5 7.8879V2.55127M7.5 7.8879V13.1156'
                    stroke='#264EC7'
                    strokeWidth='3.23529'
                    strokeLinecap='square'
                  />
                </motion.svg>
              </div>
              <h2 className='ms-2 text-base md:text-lg'>{item.Questions}</h2>
            </div>

            {openQuestionIndex === index && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div className='p-2'>
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2, duration: 0.3 }}
                  >
                    {item.Answers}
                  </motion.p>
                </div>
              </motion.div>
            )}
          </Card>
        ))}
      </Section>
    </>
  );
}
