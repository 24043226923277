import React, {PropsWithChildren, CSSProperties} from "react";
import "@/components/styles/card.scss"

interface Props {
    style?: CSSProperties
    className?: string,
    onClick?: () => void
}

export const Card: React.FC<PropsWithChildren<Props>> = ({children, ...props}) => {
    return (
        <div {...props}>
            <div className={'card'}>
                {children}
            </div>
        </div>
    )
}
