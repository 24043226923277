import React, {ReactNode, PropsWithChildren, CSSProperties, ChangeEvent, FormEvent} from 'react';
import tw from 'tailwind-styled-components';

interface InputProps {
    name?: string;
    value?: string | number | undefined;
    placeholder: string;
    className?: string;
    style?: CSSProperties;
    icon?: string;
    required?: boolean;
    onInput?: (e: FormEvent<HTMLInputElement>) => void;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const InputContainer = tw.div`
    relative
`;

const IconContainer = tw.span`
  absolute
  inset-y-0
  left-3
  flex
  items-center
`;

const CustomTextArea = tw.textarea`
  focus:shadow-outline
  leading-tight
  rounded
  pt-5
  pl-10
  focus:outline-none
`;


export const TextArea: React.FC<PropsWithChildren<InputProps>> = ({children, icon, ...props}) => (
    <InputContainer>
        {icon && <IconContainer><span className={"mdi icons-edit " + icon}/></IconContainer>}
        {/* @ts-ignore       */}
        <CustomTextArea {...props}>{children}</CustomTextArea>
    </InputContainer>
);
