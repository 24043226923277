'use client'

import {motion, Variants} from 'framer-motion';
import React, {ReactNode} from 'react';
import Media from 'react-responsive';

interface Props {
    children: ReactNode;
}

const rightContentShowDesktop: Variants = {
    hide: {
        opacity: 0,
        x: '100%',
    },
    show: {
        opacity: 1,
        x: '0%',
        transition: {
            duration: 0.8,
        },
    },
};

const rightContentShowMobile: Variants = {
    hide: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            delay: 0.2,
            duration: 0.7,
        },
    },
};

export const RightContentAnimate: React.FC<Props> = ({ children }) => {
    return (
        <div className="order-1 md:order-3">
            <Media query="(max-width: 767px)">
                {(matches) =>
                    matches ? (
                        <motion.div
                            className="block md:hidden"
                            initial="hide"
                            whileInView="show"
                            viewport={{ once: true }}
                            variants={rightContentShowMobile}
                        >
                            {children}
                        </motion.div>
                    ) : (
                        <motion.div
                            className="hidden md:block"
                            initial="hide"
                            whileInView="show"
                            viewport={{ once: true }}
                            variants={rightContentShowDesktop}
                        >
                            {children}
                        </motion.div>
                    )
                }
            </Media>
        </div>
    );
};
