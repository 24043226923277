'use client';

import React from 'react';
import Select, { StylesConfig } from 'react-select';

const customSelectStylesServices: StylesConfig = {
  control: (base: any) => ({
    ...base,
    minHeight: 48,
    paddingLeft: 5,
    color: '#BFBFBF',
    ':before': {
      content: `"\\f0349"`,
      fontFamily: 'Material Design Icons',
      fontSize: '19px',
      color: '#BFBFBF',
      marginLeft: 5,
    },
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#565656',
  }),
};

interface ServiceOption {
  value: string;
  label: string;
}

class ServiceSelect extends React.Component<{
  selectedService: any;
  handleSelectChange: any;
  servicesSelect: any;
}> {
  render() {
    let { selectedService, handleSelectChange, servicesSelect } = this.props;
    return (
      <>
        <Select
          required
          styles={customSelectStylesServices}
          className='input-pattern my-2 w-full'
          options={servicesSelect}
          value={selectedService}
          onChange={handleSelectChange}
          isMulti={true}
          placeholder='Qual serviço você precisa?'
          noOptionsMessage={({ inputValue }) => 'Nenhum serviço relacionado'}
        />
      </>
    );
  }
}

export default ServiceSelect;
