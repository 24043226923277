'use client'

import React, {ReactNode} from 'react';
import {motion, Variants} from "framer-motion";
import Media from 'react-responsive';

interface ContainerProps {
    children: ReactNode;
}

const leftContentShowDesktop: Variants = {
    hide: {
        opacity: 0,
        x: '-100%',
    },
    show: {
        opacity: 1,
        x: '0%',
        transition: {
            duration: 0.8,
        },
    },
};

export const LeftContentAnimate: React.FC<ContainerProps> = ({children}) => {
    return (
        <div className="w-100 order-2">
            <Media query="(max-width: 767px)">
                {(matches) =>
                    matches ? (
                        <div className="
                           md:hidden
                            flex
                            flex-col
                            items-center">
                            {children}
                        </div>
                    ) : (
                        <motion.div
                            className="hidden md:flex flex-col items-start"
                            initial="hide"
                            whileInView="show"
                            viewport={{once: true}}
                            variants={leftContentShowDesktop}
                        >
                            {children}
                        </motion.div>
                    )
                }
            </Media>
        </div>
    );
};
